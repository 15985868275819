<template>
  <div class="blocks">
    <div class="blocks-container">
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div class="blocks-half--block black">
          <h3 class="blocks-half--block-text-settings top--text">We are create apps for any business case</h3>
          <video
            autoplay="autoplay"
            muted="muted"
            loop="loop"
            class="blocks-half--block-video"
            playsinline="">
            <source
              src="@/assets/videos/phone.mp4"
              type="video/mp4">
          </video>
          <h3 class="blocks-half--block-text-settings bottom--text">With business level controls</h3>
        </div>
        <div class="blocks-half--block white">
          <h3 class="blocks-half--block-text-settings black--text">We manage projects and know</h3>
          <h3 class="blocks-half--block-text-settings black--text bottom">how to build methodologies correctly.</h3>
        </div>
      </div>
<!--      <div class="blocks-half&#45;&#45;block full&#45;&#45;block black">-->
<!--      </div>-->
<!--      <div class="blocks-half&#45;&#45;block full&#45;&#45;block white">-->
<!--      </div>-->
    </div>
  </div>
</template>

<script lang="ts">
</script>

<style lang="scss" scoped>
.blocks {
  position: relative;
  height: auto;
  width: 100%;
  background: linear-gradient(90.38deg,#dee9ff -88.49%,#fff 82.75%);
  padding-bottom: get-vw(70px);
  &-container {
    padding-left: get-vw(485px);
    padding-right: get-vw(485px);
  }
  &-half--block {
    width: get-vw(465px);
    height: get-vw(600px);
    //margin: 0 auto;
    box-shadow: 0 0 get-vw(50px) rgb(118 129 138 / 75%);
    border-radius: get-vw(20px);
    overflow: hidden;
    font-size: get-vw(40px);
    text-transform: uppercase;
    position: relative;
    display: flex;
    flex-direction: column;
    &-text-settings {
      font-size: get-vw(25px);
      position: relative;
      z-index: 1;
      padding: get-vw(40px) get-vw(24px);
      display:inline-block;
      -webkit-transform:scale(1,1.25);
      -moz-transform:scale(1,1.25);
      -ms-transform:scale(1,1.25);
      -o-transform:scale(1,1.25);
      transform:scale(1,1.25);
      &.top--text {
        font-size: get-vw(32px);
        color: #ffffff;
        //text-transform: uppercase;
        line-height: get-vw(40px);
        -webkit-transform:scale(1,1.20);
        -moz-transform:scale(1,1.20);
        -ms-transform:scale(1,1.20);
        -o-transform:scale(1,1.20);
        transform:scale(1,1.20);
      }
      &.black--text {
        font-size: get-vw(32px);
        color: #000000;
        //text-transform: uppercase;
        line-height: get-vw(40px);
        -webkit-transform:scale(1,1.20);
        -moz-transform:scale(1,1.20);
        -ms-transform:scale(1,1.20);
        -o-transform:scale(1,1.20);
        transform:scale(1,1.20);
        letter-spacing: get-vw(-2px);
        &.bottom {
          margin-top: auto;
        }
      }
      &.bottom--text {
        background: linear-gradient(90deg,#ffb076 -38.94%,#b9f9d1 109.81%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 800;
        margin-top: auto;
      }
    }
    &-video {
      position: absolute;
      width: get-vw(465px);
      height: get-vw(600px);
      top: 0;
      z-index: 0;
    }
    &.black {
      background: #000;
    }
    &.white {
      background-color: #fff;
    }
    &.full--block {
      width: 100%;
    }
  }
}
</style>
